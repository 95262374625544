var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "modules-wrap" },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            height: "100%",
            border: true,
            "header-cell-style": { color: "#333333", background: "#EFF6FF" },
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              align: "center",
              width: "100",
              label: "序号",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "supplier_name", align: "center", label: "供货商" },
          }),
          _c("el-table-column", {
            attrs: { prop: "buyer_name", align: "center", label: "采购" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "total_amount",
              align: "center",
              label: "金额（万元）",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "number", align: "center", label: "件数" },
          }),
          _c("el-table-column", {
            attrs: { prop: "user_count", align: "center", label: "下单客户数" },
          }),
          _c("el-table-column", {
            attrs: { prop: "avg_order", align: "center", label: "人均下单数" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }